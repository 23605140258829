.responsible {
  & .responsible-banner {
    padding: 12px 44px;
    background-color: var(--bgBanner);
    color: var(--violet);
    font-family: var(--INTER);
    font-weight: 400;
    font-size: var(--standartLg);
    line-height: 24px;
    margin-bottom: 13px;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 7px;
    padding-bottom: 25px;

    &-buttons {
      display: flex;
      gap: 20px;
    }
  }

  &-content-empty_text {
    font-family: var(--INTER);
    font-weight: 400;
    font-size: var(--standart);
    line-height: 24px;
    color: var(--additionalGrey);
  }

  &-form {
    & .adornment {
      font-family: var(--INTER);
      font-weight: 400;
      font-size: var(--standartLg);
      line-height: 24px;
      color: var(--black);
    }

    & .MuiAccordionSummary-content .responsible-form-section-label {
      font-family: var(--INTER);
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--black);
      padding-left: 8px;
    }

    & .input-wrapper .error-text {
      top: 80px;
    }
  }
}
