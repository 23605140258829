@charset "UTF-8";
.equipment_details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  gap: 15px;
}
@media (max-width: 530px) {
  .equipment_details-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.equipment_details-header-buttons {
  display: flex;
  gap: 20px;
  height: 49.6px;
  align-items: center;
}
@media (max-width: 530px) {
  .equipment_details-header-buttons {
    gap: 10px;
  }
  .equipment_details-header-buttons .btn {
    min-width: 127px;
  }
}
@media (max-width: 450px) {
  .equipment_details-header-buttons {
    flex-wrap: wrap;
    height: inherit;
    gap: 0;
    justify-content: space-between;
    row-gap: 5px;
    width: 100%;
  }
  .equipment_details-header-buttons .btn {
    min-width: inherit;
    flex-basis: 49%;
  }
  .equipment_details-header-buttons .save {
    flex-basis: 100%;
  }
}
.equipment_details-switcher_field {
  display: flex;
  justify-content: flex-start;
}
.equipment_details-switcher_field .switcher {
  flex-wrap: nowrap;
}
.equipment_details-switcher_field .switcher label {
  width: 95px;
  margin-right: 0;
}
.equipment_details-content-empty_text {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--additionalGrey);
}
.equipment_details-form .adornment {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
} 

.equipment_details-form .MuiAccordion-root {
  padding: 20px 32px;
} 

.adornment {
  margin-right: 19px;
}

.equipment_details-form .MuiAccordionSummary-content .equipment_details-form-section-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
  padding-left: 8px;
}
.equipment_details-form .input-wrapper .error-text {
  top: 80px;
}
.equipment_details-form .label {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
  color: #000000;
  margin-bottom: 4px;
}
.equipment_details-form .block_form-wrapper {
  padding: 20px 16px;
  border: 1px solid var(--borderLightGrey);
  border-radius: 5px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (max-width: 450px) {
  .equipment_details-form .block_form-wrapper .checkbox-label {
    font-size: var(--standart);
    line-height: 18px;
  }
}
.equipment_details-form .resizing_basic_forms {
  flex-basis: 45%;
}
@media screen and (max-width: 600px) {
  .equipment_details-form .resizing_basic_forms {
    flex-basis: 100%;
  }
}
.equipment_details .scrolled_equipment_form {
  max-height: 66vh;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
}
.equipment_details .scrolled_equipment_form::-webkit-scrollbar {
  /* chrome based */
  width: 0px; /* ширина scrollbar'a */
  background: transparent; /* опционально */
}
@media screen and (max-height: 715px) {
  .equipment_details .scrolled_equipment_form {
    max-height: 60vh;
  }
} /*# sourceMappingURL=style.css.map */
