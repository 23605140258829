.contact_user_modal {
  & .MuiDialog-paper {
    width: 876px;
    border-radius: 12px 12px;
  }
  & .contact_user_modal-title {
    display: flex;
    justify-content: flex-end;
    padding: 12px 14px 0;

    & .contact_user_modal-closeBtn {
      padding: 0;
    }
  }

  & .contact_user_modal-content {
    padding: 0 36px 36px;

    &-contacts {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 40px;
      gap: 40px;

      &-avatar_block {
        display: flex;
        align-items: flex-start;
        gap: 35px;
      }

      & .contact_user_modal-content-contacts-fio {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 500;
        font-size: var(--sectionTitle);
        line-height: 32px;
        color: var(--black);
        margin-bottom: 16px;
      }

      & .contact_user_modal-content-contacts-job {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 400;
        font-size: var(--standartLg);
        line-height: 24px;
        color: var(--additionalGrey);
        margin-bottom: 56px;
      }

      & .contact_user_modal-content-contacts-connection {
        display: flex;
        gap: 40px;

        & .contact_user_modal-content-contacts-connection-text {
          font-family: var(--INTER);
          font-style: normal;
          font-weight: 400;
          font-size: var(--standartLg);
          line-height: 24px;
          color: #212121;
        }
      }
    }

    &-organizations {
      & .contact_user_modal-content-organizations-title {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 500;
        font-size: var(--standartLg);
        color: var(--additionalGrey);
        border-bottom: 1px solid var(--borderLightGrey);
        height: 28px;
        margin-bottom: 20px;
      }

      & .contact_user_modal-content-organizations-text {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        font-size: var(--standartLg);
        color: var(--main);
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
