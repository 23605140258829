$mediumW: 1300px;
$smallW: 1050px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

.login-wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  box-shadow: 4px 3px 16px 1px rgba(0, 0, 0, 0.29);
  height: 100vh;

  @media screen and (max-height: $smallH) {
    height: inherit;
  }

  @media screen and (max-width: $smallW) {
    box-shadow: none;
  }

  .left_block-wrapper {
    flex-basis: 43%;
    padding: 40px 60px;
    box-sizing: border-box;
    background: #4339f2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    & .left_block-header {
      width: 100%;

      &-logo_field {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & svg {
          fill: var(--white);
          max-height: 75%;
          max-width: 75%;
        }

        & img {
          width: 13%;
        }
      }
    }

    & .left_block-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      & .left_block-title {
        border-bottom: 1px solid var(--white);
        flex-basis: 50%;
        display: flex;
        align-items: center;

        & .title {
          font-family: var(--INTER);
          font-size: 35px;
          font-weight: 600;
          line-height: 45px;
          color: var(--white);
          font-style: normal;
          text-align: center;

          @media screen and (max-width: $mediumW) {
            font-size: 25px;
            line-height: 30px;
          }

          @media screen and (max-width: $smallW) {
            font-size: var(--modalContext);
            line-height: 28px;
          }

          @media screen and (max-height: $smallH) {
            font-size: 21px;
            line-height: 25px;
          }
        }
      }

      & .left_block-list {
        flex-basis: 50%;
        display: flex;
        align-items: center;

        &-icon_wrapper {
          min-width: 80px;
        }

        & li {
          padding-left: 0;
          padding-right: 0;

          & span {
            font-family: var(--INTER);
            font-size: 24px;
            font-weight: 400;
            line-height: 34px;
            color: var(--white);

            @media screen and (max-width: $mediumW) {
              font-size: var(--titleBlock);
              line-height: 25px;
            }

            @media screen and (max-width: $smallW) {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }

  .login-title-auth {
    font-style: normal;
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
    font-family: var(--INTER);

    @media screen and (max-width: $smallW) {
      font-size: 43px;
    }

    @media screen and (max-width: $mobileXSm) {
      font-size: 32px;
    }
  }

  .login-title {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    font-family: var(--INTER);
  };

  .default-label-text {
    font-style: normal;
    font-family: var(--INTER);
    line-height: 24px;
    font-weight: 400;
    font-size: var(--standartLg);
    &.sm {
      font-size: 12px;
      line-height: 18px;
    }

    &.strong {
      font-weight: 500;
    }
  }

  .login-form {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  }

  & .left_block {
    flex-basis: 43%;
    padding: 10% 60px 40px;
    box-sizing: border-box;
    background: #4339f2;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10%;

    @media (max-width: $mobileLg) {
      display: none;
    }

    &-logo {
      margin: 0 auto;

      & img {
        width: 180px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15%;

      &-logo_text_field {
        display: flex;
        align-items: center;
        height: 30px;
      }

      & svg {
        width: 100%;
        height: 100%;
      }

      &-text {
        font-family: var(--INTER);
        font-size: 35px;
        font-weight: 500;
        line-height: 45px;
        color: var(--white);
        font-style: normal;
        text-align: center;

        @media screen and (max-width: $mediumW) {
          font-size: 25px;
          line-height: 30px;
        }

        @media screen and (max-width: $smallW) {
          font-size: 21px;
          line-height: 25px;
        }

        @media screen and (max-height: $smallH) {
          font-size: 21px;
          line-height: 25px;
        }
      }
    }
  }

  & .right_block {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    padding: 2% 0 5%;
    min-width: 25%;
    max-width: 50%;

    @media screen and (max-width: $mobileMd) {
      max-width: 80%;
    }

    & .registration_field {
      column-gap: 15px;
      & .btn-link {
        @media screen and (max-width: $mobileMd) {
          font-size: 14px;
          line-height: 20px;
        }

        @media screen and (max-width: $mobileXSm) {
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    & .confirm_conditions {
      margin: 16px 0;

      & .MuiFormControlLabel-label {
        @media screen and (max-width: $mobileMd) {
          font-size: 13px;
        }

        @media screen and (max-width: $mobileSm) {
          font-size: 12px;
        }

        @media screen and (max-width: $mobileXSm) {
          line-height: 12px;
        }
      }
    }

    & .basic_information {
      & .organization_form_field {
        display: flex;
        justify-content: flex-start;
        gap: 15px;

        &-ogrn {
          margin-top: 30px;
        }

        @media screen and (max-width: 800px) {
          flex-direction: column;

          &-ogrn {
            margin-top: 0;
            width: 210px;
          }
        }
      }

      & .variable_width_form {
        width: 65%;

        @media screen and (max-width: $smallW) {
          width: 100%;
        }
      }
    }

    & .contacts {
      & .contacts_form_field {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        flex-wrap: wrap;

        @media screen and (max-width: 910px) {
          & .input-wrapper {
            flex-basis: 100%;
          }
        }
      }
    }
  }
}
