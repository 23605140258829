.modal_table {
  & .btn-container {
    text-align: center;

    & .btn_in_table {
      padding: 5px 15px;
      font-family: var(--INTER);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 24px;
      width: 100%;
      text-transform: none;
      border-radius: 2px;
      width: 210px;
    }
  }
}
