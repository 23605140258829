.sms_code_dialog {
  position: relative;

  .sms_code_dialog-close_icon {
    position: absolute;
    padding: 0;
    top: 19px;
    right: 22px;
  }

  .sms_code_dialog-header {
    display: inline;
    font-family: var(--INTER);
    font-weight: 500;
    font-size: var(--popupTitle);
    line-height: 28px;
    color: var(--black);
    text-align: center;
    margin: 30px 20px 0;
  }

  .sms_code_dialog-content {
    &-warning_text {
      font-family: var(--INTER);
      font-weight: 500;
      font-size: var(--underInput);
      text-align: center;
      color: red;
    }
    &-text {
      text-align: center;
    }
  }

  .sms_code_dialog-submit_btn_wrapper {
    justify-content: center;
    padding: 0;
    padding-bottom: 46px;
  }

  & .sms_code {
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;

    &-input {
      width: 40px !important;
      height: 60px;
      font-family: var(--INTER);
      font-weight: 500;
      font-size: var(--popupTitle);
      line-height: 28px;
      color: var(--black);
      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type="number"] {
        appearance: textfield;
      }
    }
  }
}
