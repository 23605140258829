.signatures-button_wrraper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .task_dialog .task_dialog-header {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.task_dialog .section_form {
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 600px) {
  .task_dialog .section_form {
    flex-direction: column;
    gap: 0;
  }
}
.task_dialog .section_form .resizing {
  flex-basis: 49%;
}
@media screen and (max-width: 600px) {
  .task_dialog .section_form .resizing {
    flex-basis: 100%;
  }
}
@media screen and (max-width: 600px) {
  .task_dialog .files_form .input-wrapper .input_file_block {
    flex-direction: column;
    gap: 25px;
  }
  .task_dialog .files_form .input-wrapper .input_file_block .input_block_label-helper {
    width: 167px;
  }
  .task_dialog .files_form .input-wrapper .input_file_block-preview_field {
    flex-direction: column;
    justify-content: flex-start;
  }
  .task_dialog .files_form .input-wrapper .input_file_block-preview_field-item {
    justify-content: flex-start;
  }
  .task_dialog .files_form .input-wrapper .input_file_block-preview_field-item_chip {
    max-width: 250px;
  }
}
@media screen and (max-width: 600px) and (max-width: 450px) {
  .task_dialog .files_form .input-wrapper .input_file_block-preview_field-item_chip {
    max-width: 170px;
  }
}
.task_dialog .task_details_form .input-wrapper .medium_container {
  max-width: 49%;
}
@media screen and (max-width: 600px) {
  .task_dialog .task_details_form .input-wrapper .medium_container {
    max-width: 100%;
  }
}/*# sourceMappingURL=styles.css.map */