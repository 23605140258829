.chart_card_container {
  background-color: var(--white);
  padding: 12px;
  box-sizing: border-box;
  border-radius: 12px;
  flex-basis: 60%;
}
.chart_card_container-title.MuiTypography-root {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
  color: var(--black);
}
.chart_card_container-values {
  padding: 12px 0 20px;
}
.chart_card_container-values-sum_value_title.MuiTypography-root {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}
.chart_card_container-values-percentage_value_title.MuiTypography-root {
  font-family: var(--INTER);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--additionalGrey);
}
.chart_card_container-diagram_wrapper {
  height: 195px;
}
.chart_card_container-diagram_wrapper canvas {
  width: 100% !important;
  height: 100% !important;
}
@media (max-width: 1400px) {
  .chart_card_container {
    padding: 8px;
  }
  .chart_card_container-title.MuiTypography-root {
    font-size: 14px;
    line-height: 21px;
  }
  .chart_card_container-values {
    padding: 8px 0 10px;
  }
  .chart_card_container-values-sum_value_title.MuiTypography-root {
    font-size: 32px;
    line-height: 40px;
  }
  .chart_card_container-values-percentage_value_title.MuiTypography-root {
    font-family: var(--INTER);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }
  .chart_card_container-diagram_wrapper {
    height: 235px;
  }
} /*# sourceMappingURL=style.css.map */
