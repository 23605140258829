.balloon_content {
  padding: 0 14px;

  &-list {
    list-style-type: none;
    padding-left: 0;

    &-item {
      list-style: none;
      margin-bottom: 8px;
      font-size: 12px;
      font-weight: 400;
      cursor: pointer;

      & .link-black {
        color: var(--black);
        text-decoration: none;
        margin: 0;

        &:hover {
          color: var(--main);
        }

        & .link_point {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
  }
}

.balloon_footer {
  &-text {
    list-style: none;
    font-family: var(--INTER);
    color: var(--main);
    font-size: var(--standart);
    font-weight: bold;
    text-decoration: none;
    text-transform: none;
    text-align: end;
    cursor: pointer;
  }
}
