$mediumW: 1300px;
$smallW: 1050px;
$mediumH: 715px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

.equipment_details {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 7px;
    gap: 15px;

    @media (max-width: 530px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &-buttons {
      display: flex;
      gap: 20px;
      height: 49.6px;
      align-items: center;

      @media (max-width: 530px) {
        gap: 10px;

        & .btn {
          min-width: 127px;
        }
      }

      @media (max-width: $mobileSm) {
        flex-wrap: wrap;
        height: inherit;
        gap: 0;
        justify-content: space-between;
        row-gap: 5px;
        width: 100%;

        & .btn {
          min-width: inherit;
          flex-basis: 49%;
        }

        & .save {
          flex-basis: 100%;
        }
      }
    }
  }

  &-switcher_field {
    display: flex;
    justify-content: flex-start;
    padding-left: 16px;

    & .switcher {
      flex-wrap: nowrap;
    }

    & .switcher label {
      width: 95px;
      margin-right: 0;
    }
  }

  &-content-empty_text {
    font-family: var(--INTER);
    font-weight: 400;
    font-size: var(--standart);
    line-height: 24px;
    color: var(--additionalGrey);
  }

  &-form {
    & .adornment {
      font-family: var(--INTER);
      font-weight: 400;
      font-size: var(--standartLg);
      line-height: 24px;
      color: var(--black);
    }

    & .MuiAccordionSummary-content .equipment_details-form-section-label {
      font-family: var(--INTER);
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--black);
      padding-left: 8px;
    }

    & .input-wrapper .error-text {
      top: 80px;
    }

    & .label {
      font-family: var(--INTER);
      font-style: normal;
      font-weight: 500;
      font-size: var(--standart);
      line-height: 20px;
      color: #212121;
      margin-bottom: 4px;
    }

    & .block_form-wrapper {
      padding: 20px 16px;
      border: 1px solid var(--borderLightGrey);
      border-radius: 5px 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      & .checkbox-label {
        @media screen and (max-width: $mobileSm) {
          font-size: var(--standart);
          line-height: 18px;
        }
      }
    }

    & .resizing_basic_forms {
      flex-basis: 45%;

      @media screen and (max-width: $mobileMd) {
        flex-basis: 100%;
      }
    }
  }

  .scrolled_equipment_form {
    max-height: 66vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* chrome based */
      width: 0px; /* ширина scrollbar'a */
      background: transparent; /* опционально */
    }

    @media screen and (max-height: $mediumH) {
      max-height: 60vh;
    }
  }
}
