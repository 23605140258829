:root {
  --standartVeryLarge: 20px; 
  --heighHeader: 90px;

  --widthShortSideBar: 114px;
  --widthFullSideBar: 379px;
  --arrowBtn: 28px;
  --small: 10px;
  --underInput: 12px;
  --standart: 14px;
  --standartLg: 16px;
  --titleBlock: 18px;
  --modalContext: 24px;
  --sectionTitle: 24px;
  --popupTitle: 24px;
  --titlePage: 32px;
  --mdScreen: 1200px;
  --tabletWidth: 900px;
}/*# sourceMappingURL=sizes.css.map */