.acts-container {
  .acts-header {
    flex-wrap: wrap;
    padding-top: 7px;
    margin-bottom: 20px;
    &-form {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      &-buttons {
        display: flex;
        gap: 20px;
        margin-bottom: 25px;
        height: 49.6px;
      }
    }
    &-list {
      max-width: 410px;
      padding: 0;
      & .MuiListItem-root {
        color: var(--main);
        font-size: var(--sectionTitle);
      }
    }
  }

  .icon-btn {
    height: max-content;
    display: flex;
    align-items: center;
    padding: 16px;
  }

  .signature_field {
    & .Mui-disabled {
      border: 1px solid rgba(0, 0, 0, 0.26);
    }
    &-btn {
      font-family: var(--INTER);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 24px;
      width: 100%;
      text-transform: none;
      border-radius: 2px;
      color: var(--main);
      border: 1px solid var(--main);
      background: var(--outlined);

      &:hover {
        border: 1px solid var(--main);
        box-shadow:
          0px 1px 1px rgba(67, 57, 242, 0.11),
          0px 0px 0px 4px rgba(67, 57, 242, 0.12);
      }
    }

    &-content {
      padding-top: 5px;

      &-title {
        font-weight: 500;
      }
    }
  }
}
