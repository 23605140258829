.stepper {
  .MuiStepLabel-iconContainer {
    cursor: pointer;

    & svg {
      width: 52px;
      height: 52px;
    }

    & .Mui-active,
    .Mui-completed {
      fill: var(--main);
    }
  }

  .MuiStepLabel-vertical {
    & .MuiStepLabel-label {
      display: none;
    }
  }

  .MuiStepLabel-labelContainer {
    & .MuiStepLabel-label {
      font-family: var(--INTER);
      font-style: normal;
      font-weight: 500;
      font-size: var(--standartLg);
      line-height: 24px;
      text-align: center;
      color: var(--lightGrey);
    }

    & .Mui-active,
    .Mui-completed {
      color: var(--black);
    }
  }
}
