.date-range-picker {
    font-family: 'Inter', sans-serif; /* Шрифт в стиле MUI */
    margin: 20px;
    
}
.react-datepicker{
    padding:10px;
    
}
.react-datepicker-popper{
    /*left: 40px !important;*/
    z-index: 99999999;
    /*transform: translate(-45px, 58px) !important;*/
}
.input-group {
    display: flex;
    align-items: center;
}
.react-datepicker-wrapper{
    min-width:calc(100% - 50px);
}
.date-picker-input {
    border: 0px;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    transition: border 0.3s;
    width: 100%; /* Ширина инпута */
    font-family: 'Inter';
    color: var(--headers);
    cursor: default;
}
.react-datepicker__day-name{
    color:#6E7781;
    width:30px;
    margin:0px 6px;   
}
.react-datepicker__day {
    margin: 6px; /* Отступ между днями */
    width: 30px;
    height:30px;
    font-size:14px !important;
    align-content: center;
}
.react-datepicker__day-names{
    border-bottom: 0px;
}
.react-datepicker__input-container{
    width: calc(100% - 40px);
}
.react-datepicker__close-icon::after{

}
.react-datepicker__day--keyboard-selected{
    background-color:#1876D2;
    color:white
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){
    background-color:#1876D2;
    color:white
}
.date-picker-input:focus {
    outline: none;
    border-color: #3f51b5; /* Цвет рамки при фокусе */
}

.calendar-icon {
    cursor: pointer;
    margin-left: 10px;
    color: #3f51b5; /* Основной цвет иконки */
    font-size: 1.5em; /* Размер иконки */
}

.reset-button {
    margin-left: 10px;
    background-color: #3f51b5; /* Основной цвет кнопки */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    cursor: pointer;
    transition: background 0.3s;
}

.reset-button:hover {
    background-color: #303f9f; /* Цвет кнопки при наведении */
}

/* Стили для календаря */
.react-datepicker {
    font-family: 'Roboto', sans-serif; /* Шрифт для календаря */
    border-radius: 8px;
}

.react-datepicker__header {
    background-color: white; /* Цвет заголовка календаря */
    color: #1876D2; /* Цвет текста заголовка */
}

.react-datepicker__day {
    font-size: 1rem;
}

.react-datepicker__day:hover {
    background-color: rgba(63, 81, 181, 0.2); /* Цвет при наведении */
}

.react-datepicker__day--selected {
    background-color: #3f51b5; /* Цвет выбранного дня */
    color: white; /* Цвет текста выбранного дня */
}
.react-datepicker__day--in-range {
    background-color: #1876D2; /* Цвет для диапазона выбранных дней */
    color: white; /* Цвет текста для диапазона */
}

.react-datepicker__month {
    margin: 0; /* Убираем отступы */
}

.react-datepicker__navigation {
    background-color: #3f51b5; /* Цвет стрелок навигации */
    border: none;
    color: white; /* Цвет иконок */
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
    background-color: white; /* Цвет фона выпадающих списков */
    border: 1px solid #ccc; /* Рамка выпадающих списков */
}

.react-datepicker__month-dropdown--scroll {
    z-index: 10; /* Увеличиваем z-index для выпадающего меню */
}

.react-datepicker__day--outside-month {
    color: #ccc; /* Цвет дней, находящихся вне текущего месяца */
}

.react-datepicker__time-container {
    display: none; /* Скрываем время (если не нужно) */
}
.date-picker-input::placeholder {
    color: var(--headers); /* Цвет текста-подсказки */
}