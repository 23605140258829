.application {
  padding: 32px;
  border-radius: 20px;
  background-color: var(--accent);
  color: var(--white);
}

.application-header {
  display: flex;
  justify-content: space-between;
}

.application-header p {
  font-size: 20px;
  font-weight: 600;
}

.application-form {
    width: 556px;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 24px;
}

.application-form .application-form-title {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 48px;
    width: 368px;
    margin: auto;
}

.application-form .input-wrapper {
    padding: 0px;
    color: var(--white);
}

.application-form .input-wrapper label {
    color: var(--white);
    height: fit-content;
}

.application-form .input-wrapper fieldset {
    border: 1px solid #D1D8E0;
    border-color: white !important;
}

.application-form .input-wrapper input {
    color: white;
}

.application-form .add-application {
    background-color: var(--white);
    color: var(--mainText);
    width: fit-content;
    padding: 16.5px 24px;
    margin: auto;
    margin-top: 24px;
}

.application-form .add-application:hover {
    background-color: var(--white);
}

.application-form .input-wrapper .MuiTypography-root {
    color: var(--white);
    margin-left: 8px;
}
.application-form .input-wrapper .MuiOutlinedInput-notchedOutline:hover {
    border-color: var(--accent);
}

.application-form .input-wrapper svg {
    color: var(--white);
}

.application-form .input-wrapper .MuiOutlinedInput-input:hover {
    color: var(--white)
}

.application-form .input-wrapper .MuiOutlinedInput-input:focus {
    color: var(--white) 
}


