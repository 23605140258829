.details {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 36px;

    .details-header-title {
      font-family: var(--INTER);
      font-weight: 500;
      font-size: var(--modalContext);
      line-height: 28px;
      color: var(--black);
      padding-right: 36px;
    }
  }
  .MuiStack-root .css-1xhypcz-MuiStack-root {
    padding: 0;
  }

  & .details-body {
    padding: 0;
  }

  &-body {
    & .MuiTabs-root {
      padding-left: 19px;
    }

    .MuiTabs-root .Mui-selected {
      color: var(--black);
    }

    .label {
      text-transform: initial;
      font-family: var(--INTER);
      font-style: normal;
      font-weight: 500;
      font-size: var(--standartLg);
      line-height: 24px;
      color: var(--black);
    }

    .requisites {
      &-btn-field {
        display: flex;
        justify-content: flex-end;
        padding: 32px;
        padding-bottom: 0;
      }

      &-content {
        padding: 0 32px;

        &-block {
          padding-bottom: 24px;

          &-title {
            font-family: var(--INTER);
            font-weight: 500;
            font-size: var(--standartLg);
            line-height: 20px;
            color: var(--additionalGrey);
            border-bottom: 1px solid var(--bgTableHeader);
            padding-bottom: 4px;
          }

          &-textField {
            display: flex;
            justify-content: space-between;
            gap: 24px;

            &-label {
              font-family: var(--INTER);
              font-weight: 500;
              font-size: var(--standartLg);
              line-height: 20px;
              color: var(--black);
              padding-top: 20px;
            }

            &-text {
              font-family: var(--INTER);
              font-weight: 400;
              font-size: var(--standartLg);
              line-height: 20px;
              color: var(--black);
              padding-top: 20px;
            }
          }
        }
      }
    }

    .objects {
      padding: 24px 36px;

      &-btn-field {
        display: flex;
        justify-content: flex-end;
        gap: 24px;
        padding-bottom: 24px;
      }

      &-item {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 400;
        font-size: var(--standartLg);
        line-height: 24px;
        color: var(--black);
      }
    }
  }
}
