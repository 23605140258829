.contacts-list-item {
    padding: 14px 26px;
    color: var(--accent);
    background-color: #EEF4FD;
    border-radius: 32px;
    margin-bottom: 24px;
}

.contacts-list-item a {
    display: flex;
    align-items: center;
    color: var(--accent);
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--INTER);
}

.contacts-list-item a:hover {
    color: var(--hover);
}

.contacts-list-item svg {
    margin-right: 10px;
}

.contacts-title {
    margin: 0 0 48px 0;
    font-size: 24px;
    font-weight: 500;
    color: var(--headers);
}