.login_wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  box-shadow: 4px 3px 16px 1px rgba(0, 0, 0, 0.29);
  height: 100vh;
}
@media screen and (max-height: 670px) {
  .login_wrapper {
    height: inherit;
  }
}
@media screen and (max-width: 1050px) {
  .login_wrapper {
    box-shadow: none;
  }
}
.login_wrapper .login-title-auth {
  font-style: normal;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
  font-family: var(--INTER);
}
@media screen and (max-width: 1050px) {
  .login_wrapper .login-title-auth {
    font-size: 43px;
  }
}
@media screen and (max-width: 350px) {
  .login_wrapper .login-title-auth {
    font-size: 32px;
  }
}
.login_wrapper .login-title {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  font-family: var(--INTER);
}
.login_wrapper .default-label-text {
  font-style: normal;
  font-family: var(--INTER);
  line-height: 24px;
  font-weight: 400;
  font-size: var(--standartLg);
}
.login_wrapper .default-label-text.sm {
  font-size: 12px;
  line-height: 18px;
}
.login_wrapper .default-label-text.strong {
  font-weight: 500;
}
.login_wrapper .login-form {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}
.login_wrapper .left_block {
  flex-basis: 43%;
  padding: 0;
  box-sizing: border-box;
  background: #4339f2;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10%;
}
@media (max-width: 750px) {
  .login_wrapper .left_block {
    display: none;
  }
}
.login_wrapper .left_block-logo {
  margin: 0 auto;
}
.login_wrapper .left_block-logo img {
  width: 180px;
}
.login_wrapper .left_block-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15%;
}
.login_wrapper .left_block-content-logo_text_field {
  display: flex;
  align-items: center;
  height: 30px;
}
.login_wrapper .left_block-content svg {
  width: 100%;
  height: 100%;
}
.login_wrapper .left_block-content-text {
  font-family: var(--INTER);
  font-size: 35px;
  font-weight: 500;
  line-height: 45px;
  color: var(--white);
  font-style: normal;
  text-align: center;
}
@media screen and (max-width: 1300px) {
  .login_wrapper .left_block-content-text {
    font-size: 25px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1050px) {
  .login_wrapper .left_block-content-text {
    font-size: 21px;
    line-height: 25px;
  }
}
@media screen and (max-height: 670px) {
  .login_wrapper .left_block-content-text {
    font-size: 21px;
    line-height: 25px;
  }
}
.login_wrapper .right_block {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  padding: 10% 0 5%;
  min-width: "790px";
  max-width: 50%;
}
@media screen and (max-width: 600px) {
  .login_wrapper .right_block {
    max-width: 80%;
  }
}
.login_wrapper .right_block .first_step-btn_next {
  margin-top: 30px;
  margin-bottom: 30px;
}
@media screen and (max-width: 350px) {
  .login_wrapper .right_block .first_step-btn_next {
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .login_wrapper .right_block .btn-link {
    font-size: 14px;
    line-height: 20px;
  }
}
@media screen and (max-width: 350px) {
  .login_wrapper .right_block .btn-link {
    font-size: 12px;
    line-height: 12px;
  }
}
.login_wrapper .right_block .confirm_conditions {
  margin: 16px 0;
}
@media screen and (max-width: 600px) {
  .login_wrapper .right_block .confirm_conditions .MuiFormControlLabel-label {
    font-size: 13px;
  }
}
@media screen and (max-width: 450px) {
  .login_wrapper .right_block .confirm_conditions .MuiFormControlLabel-label {
    font-size: 12px;
  }
}
@media screen and (max-width: 350px) {
  .login_wrapper .right_block .confirm_conditions .MuiFormControlLabel-label {
    line-height: 12px;
  }
} /*# sourceMappingURL=style.css.map */
