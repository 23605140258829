.profile_container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 25px;
}
.profile_container-header-buttons {
  display: flex;
  gap: 20px;
}

.profile {
  display: flex;
  gap: 32px;
  width: 90%;
}

@media (max-width: 1660px) {
  .profile {
    width: 100%;
  }
}

.profile p {
  margin: 0;
}

.profile-notification {
  display: flex;
  border-radius: 32px;
  padding: 32px;
  background-color: var(--white);
  color: var(--text-color);
  font-size: 16px;
}

.disabled {
  pointer-events: none;
} 

.user-profile {
  display: flex;
  gap: 32px;
  border-radius: 32px;
  padding: 32px;
  background-color: var(--white);
  color: var(--text-color);
  font-size: 16px;
}

.user-profile__img {
  height: 200px;
  width: 200px;
  border-radius: 50%;
}

.user-profile__img img, .user-profile__img svg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}


.user-profile__info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.user-profile__name {
  font-size: 20px;
}

.user-profile__roles {
  display: flex;
  gap: 16px;
}

.user-profile__roles div {
  padding: 8px 16px;
  border: 1px solid #cfd9e8;
  border-radius: 32px;
}

.user-profile__number {
  display: flex;
  align-items: center;
  gap: 24px;
}

.user-profile__confirmation {
  display: flex;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 32px;
}

.succes {
  background-color: #e7ffe9;
  color: var(--succes);
}

.bad {
  background-color: transparent;
  color: var(--accent);
  cursor: pointer;
}

.bad:hover {
  color: var(--hover);
}

.user-profile__job-name {
  color: var(--accent);
}

.profile-notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.profile-notification__title {
  color: #4D5C6E;
  font-size: 20px;
}

.edit-btn svg {
  margin-right: 19px;
}