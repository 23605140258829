.employee-card {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 11px 0;
}

.employee-card-image {
    width: 40px;
    height: 40px;
}

.employee-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.employee-card-info p:first-child {
    color: var(--accent);
    cursor: pointer;
}

.employee-card-info p:last-child {
    color: var(--headers);
}

