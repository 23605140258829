.edit-profile {
    display: flex;
    gap: 32px;
}

.edit-profile__roles {
    display: flex;
    gap: 16px;
    font-size: 16px;
    font-weight: 500;
}

.edit-profile__img img {
    height: 203px;
    width: 203px;
    border-radius: 50%;
}

.edit-profile__roles div {
    padding: 8px 16px;
    color: #D1D8E0;
    border-radius: 32px;
    border: 1px solid #CFD9E8;
    background-color: #eef0f6;
}

.edit-profile__main {
    display: flex;
    gap: 32px;
    border-radius: 32px;
    padding: 32px;
    background-color: var(--white);
}

.edit-profile-notification {
    height: fit-content;
    padding: 32px;
    border-radius: 32px;
    background-color: var(--white);
}