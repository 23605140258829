.chart_card_container {
  background-color: var(--white);
  padding: 12px;
  box-sizing: border-box;
  border-radius: 12px;
  flex-basis: 60%;

  &-title {
    &.MuiTypography-root {
      font-family: var(--INTER);
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      color: var(--black);
    }
  }

  &-values {
    padding: 12px 0 20px;

    &-sum_value_title {
      &.MuiTypography-root {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
      }
    }
    &-percentage_value_title {
      &.MuiTypography-root {
        font-family: var(--INTER);
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--additionalGrey);
      }
    }
  }

  &-diagram_wrapper {
    height: 195px;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }

  @media (max-width: 1400px) {
    padding: 8px;
    &-title {
      &.MuiTypography-root {
        font-size: 14px;
        line-height: 21px;
      }
    }
    &-values {
      padding: 8px 0 10px;
      &-sum_value_title {
        &.MuiTypography-root {
          font-size: 32px;
          line-height: 40px;
        }
      }
      &-percentage_value_title {
        &.MuiTypography-root {
          font-family: var(--INTER);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
        }
      }
    }
    &-diagram_wrapper {
      height: 235px;
    }
  }
}
