.acts-container .acts-header {
  flex-wrap: wrap;

}
.acts-container .acts-header-form {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  align-items: center;
}
.acts-container .acts-header-form-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  height: 49.6px;
}
.acts-container .acts-header-list {
  max-width: 410px;
  padding: 0;
}
.acts-container .acts-header-list .MuiListItem-root {
  color: var(--main);
  font-size: var(--sectionTitle);
}
.acts-container .icon-btn {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  align-items: center;
  padding: 16px;
}

.acts-container .signature_field .Mui-disabled {
  border: 1px solid rgba(0, 0, 0, 0.26);
}
.acts-container .signature_field-btn {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  line-height: 24px;
  width: 100%;
  text-transform: none;
  border-radius: 2px;
  color: var(--main);
  border: 1px solid var(--main);
  background: var(--outlined);
}
.acts-container .signature_field-btn:hover {
  border: 1px solid var(--main);
  box-shadow:
    0px 1px 1px rgba(67, 57, 242, 0.11),
    0px 0px 0px 4px rgba(67, 57, 242, 0.12);
}
.acts-container .signature_field-content {
  padding-top: 5px;
}
.acts-container .signature_field-content-title {
  font-weight: 500;
} /*# sourceMappingURL=style.css.map */
