.new_password_dialog {
  & .MuiDialog-paper {
    background-color: var(--white);
    padding: 25px 40px;
    width: 45vw;
    margin: 0;
  }

  position: relative;

  .new_password_dialog-close_icon {
    position: absolute;
    padding: 0;
    top: 19px;
    right: 22px;
  }

  &.MuiDialogTitle-root {
    padding: 0;
  }

  .new_password_dialog-header {
    display: inline;
    font-family: var(--INTER);
    font-weight: 500;
    font-size: var(--popupTitle);
    line-height: 28px;
    color: var(--black);
    margin-top: 30px;
  }

  &-submit_btn_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
  }

  .MuiAlert-root {
    padding: 18px;
    font-family: var(--INTER);
    font-style: normal;
    font-weight: 400;
    font-size: var(--standartLg);
    line-height: 24px;
    display: flex;
    align-items: center;

    &.MuiAlert-filledSuccess {
      background-color: var(--successBgSecondary);
      color: var(--successTextSecondary);
    }

    &.MuiAlert-filledError {
      background-color: var(--errorBg);
      color: var(--errorText);
    }

    & .MuiAlert-message {
      padding: 0;
    }
    & .MuiAlert-icon {
      font-size: var(--standartLg);
      height: var(--standartLg);
      width: var(--standartLg);
      padding: 0;
    }
    svg {
      font-size: var(--standartLg);
      height: var(--standartLg);
      width: var(--standartLg);
    }
  }
}
