.edit-news-modal {
    padding: 32px 50px 8px;
}

.edit-news-modal-header {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    color: var(--mainText);
    margin-bottom: 20px;
}

.edit-news-modal-header svg:hover {
    color: var(--accent);
    cursor: pointer;
}

.edit-news-modal-header p {
    font-size: 20px;
}

.add-news-form .text-editor {
    margin-top: 12px;
    margin-bottom: 24px;
}

