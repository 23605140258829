@keyframes blink {
  0% { stroke: #ff0000; }
  50% { stroke: #ffff00; }
  100% { stroke: #ff0000; }
}

.blinking {
  animation: blink 1s infinite;
}
.plume {
  &-form_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      &-manage {
        height: 45px;
        border: 1px solid var(--main);
        padding: 0px 12px;
        display: flex;
        align-items: center;

        & button {
          min-width: inherit;
          padding: 0 12px;
        }
      }
    }

    &-select_container {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  &-image_container {
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    // min-height: 400px;

    & .sub_notification {
      padding: 10px 20px;
      border-radius: 4px 4px;
      background-color: var(--white);
      position: absolute;
      z-index: 1;

      & button {
        min-width: inherit;
        padding: 0 6px;
      }
    }

    &-schema {
      display: flex;
      justify-content: center;
      align-items: center;

      & .schema-field-image-missing {
        display: flex;
        flex-direction: column;
        align-items: center;
    
        & .btn {
          width: 167px;
        }
      }
    }
  }
}