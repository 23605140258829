.news-widget {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 32px;
    background: linear-gradient(to top right, #A4CEF5, #D7E7FC);
    color: #272956;
}

.news-widget-dote-container {
    display: flex;
    justify-content: center;
    gap: 6px;
    margin-bottom: 8px;
}

.news-widget-dote {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--white);
    opacity: .4;
    cursor: pointer;
    transition: .4;
}

.news-widget-dote:hover {
    scale: 1.3;
    transition: .7;
}

.news-widget-dote.active {
    opacity: 1;
}

.news-widget-info {
    display: flex;
    flex-direction: column;
}

.news-widget-info > * {
    margin: 0;
}

.news-widget-title {
    font-weight: 500;
    font-size: var(--standartVeryLarge);
    margin-bottom: 11px;
}

.news-widget-date {
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.41);
    font-weight: 500;
}

.news-widget-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    -webkit-line-clamp: 2;
}