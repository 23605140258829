.object-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 25px;
}
.object-header-buttons {
  display: flex;
  gap: 20px;
}
.object-content-empty_text {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--additionalGrey);
}
.object-form .adornment {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
}
.object-form .MuiAccordionSummary-content .object-form-section-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
  padding-left: 8px;
}

.percent-text {
  position: absolute;
  right: 38%;
  top: 18%;
  font-weight: 500;
}

.object-bottom_buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
} /*# sourceMappingURL=style.css.map */


.objects-tabs {
  width: fit-content;
  padding: 8px;
  border-radius: 32px;
  background-color: var(--white);
  margin-bottom: 32px;
}