.tariff {
    padding: 40px;
    color: var(--mainText)
}

.tariff-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.tariff-close-btn {
    position: absolute;
    right: 0;
    color: var(--mainText);
    padding: 8px;
    cursor: pointer;
    transition: .3s;
}

.tariff-close-btn:hover {
    color: var(--accent);
    transition: .3s;
}

.tariff-title p {
    font-size: 20px;
}

.tariff-header {
    display: flex;
    align-items: center;
    gap: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--stroke);
}

.tariff-img {
    width: 140px;
    height: 140px;
}

.tariff-default-icon {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: #c0c0c0;
}

.tariff-selected-wrapper {
    display: flex;
    justify-content: space-between;
    height: 36px;
}

.tariff-block-selected {
    padding: 6px 10px;
    background-color: var(--accent);
    border-radius: 24px;
    color: var(--white);
}

.tariff-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.tariff-object-info {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.tariff-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tariff-info p:first-child {
    color: var(--headers);
}

.tariff-body {
    padding: 24px 0 42px 0;
}

.tariff-blocks-container {
    display: grid;
    grid-template-columns: repeat(3, 217px);
    column-gap: 16px;
    row-gap: 24px;
    padding-top: 24px;
}

.tariff-block-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tariff-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 12px 24px;
    border-radius: 20px;
    background-color: #EEF4FD;
    cursor: pointer;
}

.tariff-block.active {
    border: 1px solid var(--accent);
    padding: 11px 23px;
}

.tariff-block-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.tariff-block-info p:first-child {
    color: var(--accent);
    font-size: 32px;
    font-weight: 600;
    line-height: 32px;
}

.tariff-cost {
    padding-left: 24px;
    color: var(--headers); 
}