$mobileMd: 600px;
$mobileSm: 450px;

.magazine_finished_tasks_page {
  & .magazine_events-select_container-btn {
    height: 49px;

    @media screen and (max-width: $mobileSm) {
      width: 100%;
      margin-right: 0;
    }
  }
  & .search_field {
    margin-bottom: 24px;

    @media (max-width: 1375px) {
      margin-top: 10px;
    }

    & .medium_container {
      max-width: 35%;

      @media screen and (max-width: $mobileMd) {
        max-width: 100%;
      }
    }
  }
}