.responsible .responsible-banner {
  padding: 12px 44px;
  background-color: var(--bgBanner);
  color: var(--violet);
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  margin-bottom: 13px;
}
.responsible-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 25px;
}
.responsible-header-buttons {
  display: flex;
  gap: 20px;
}
.responsible-content-empty_text {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--additionalGrey);
}
.responsible-form .adornment {
  font-family: var(--INTER);
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--black);
}
.responsible-form .MuiAccordionSummary-content .responsible-form-section-label {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: var(--black);
  padding-left: 8px;
}
.responsible-form .input-wrapper .error-text {
  top: 80px;
}

.add-organization-block {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

/*# sourceMappingURL=style.css.map */
