.read_only_personal_data_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.read_only_personal_data_container-item {
  flex-basis: 33%;
  padding-bottom: 10px;
}
.read_only_personal_data_container-item-label {
  font-style: normal;
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
  color: var(--additionalGrey);
  font-family: var(--INTER);
  margin-bottom: 10px;
}
.read_only_personal_data_container-item-content {
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 20px;
  color: var(--black);
  font-family: var(--INTER);
  height: 1.4375em;
  padding: 16.5px 0;
} /*# sourceMappingURL=styles.css.map */
