$mediumW: 1300px;
$smallW: 1050px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

.signatures {
  &-button_wrraper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;
  }
}

.task_dialog {
  & .task_dialog-header {
    @media screen and (max-width: $mobileMd) {
      padding-top: 25px;
      padding-bottom: 25px;
    }  
  }

  & .section_form {
    display: flex;
    gap: 10px;

    @media screen and (max-width: $mobileMd) {
      flex-direction: column;
      gap: 0;
    }  

    & .resizing {
      flex-basis: 49%;
  
      @media screen and (max-width: $mobileMd) {
        flex-basis: 100%;
      }  
    }
  }

  & .files_form {
    & .input-wrapper {
      & .input_file_block {
        @media screen and (max-width: $mobileMd) {
          flex-direction: column;
          gap: 25px;

          & .input_block_label-helper {
            width: 167px;
          }

          &-preview_field {
            flex-direction: column;
            justify-content: flex-start;

            &-item {
              justify-content: flex-start;
            }

            &-item_chip {
              max-width: 250px;

              @media screen and (max-width: $mobileSm) {
                max-width: 170px;
              }
            }
          }
        }
      }
    }
  }

  & .task_details_form {
    & .input-wrapper {
      & .medium_container {
        max-width: 49%;
  
        @media screen and (max-width: $mobileMd) {
          max-width: 100%;
        }
      }
    }
  }
}