.tasks-count-widget {
    display: flex;
    flex-direction: column;
    padding: 32px;
    border-radius: 32px;
    background: linear-gradient(to top right, #D7E7FC, #A4CEF5);
    color: #272956;
    font-size: 16px;
}

.tasks-count-widget-loading {
    justify-content: center;
    align-items: center;
}

.tasks-count-widget-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}

.tasks-count-widget-header a {
    text-decoration: none;
    color: #272956;
}

.tasks-count-widget-header a:hover {
    color: var(--hover)
}

.tasks-count-widget-title-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tasks-count-widget-count {
    font-size: 40px;
    color: var(--white);
}

.tasks-count-widget-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 24px;
}

.tasks-count-widget-list {
    display: flex;
    justify-content: space-between;
}

.tasks-count-widget-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.tasks-count-widget-item span {
    color: var(--white);
}

.tasks-count-widget-item p {
    margin: 0 0 16px 0;
}
.tasks-count-widget {
    border-radius: 32px;
    background: linear-gradient(to top right, #D7E7FC, #A4CEF5);
    color: #272956;
    font-size: 16px;
}

.tasks-count-widget-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
}

.tasks-count-widget-header svg {
    margin-left: 16px;
}

.tasks-count-widget-header a {
    text-decoration: none;
    color: #272956;
    height: fit-content;
}

.tasks-count-widget-header a:hover {
    color: var(--hover)
}

.tasks-count-widget-title-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 24px;
}

.tasks-count-widget-count {
    font-size: 32px;
    color: var(--white);
}

.tasks-count-widget-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}


.tasks-count-widget-item:first-child {
    padding-left: 0;
}

.tasks-count-widget-item:last-child {
    padding-right: 0;
}

