.analytics_panel {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.font-large{
  color:#fff
}
.font-medium{
  color:#fff
}
.analytics_panel .analytics_panel-block {
  height: 60px;
  border: 1px solid var(--borderLightGrey);
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  display: flex;
  gap: 10px;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  flex-basis: 18%;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.analytics_panel .analytics_panel-block:first-child:hover {
  border: 1px solid var(--borderLightGrey);
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
}
.analytics_panel .analytics_panel-block:hover {
  border: 1px solid rgba(67, 57, 242, 0.7);
  box-shadow: 0px 0px 8px rgba(67, 57, 242, 0.5);
}

.widgets-container {
  display: flex;
  gap: 16px;
}

.widgets-container > div {
  width: 100%;
}

@media (max-width: 1715px) {
  .analytics_panel .analytics_panel-block {
    padding: 5px 10px;
  }
}
@media (max-width: 1300px) {
  .analytics_panel .analytics_panel-block {
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 0px;
    height: initial;
  }
}
.analytics_panel .analytics_panel-block-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1300px) {
  .analytics_panel .analytics_panel-block-wrapper {
    padding-left: 20px;
  }
}
.analytics_panel .analytics_panel-block-name {
  font-family: var(--INTER);
  font-weight: 500;
  font-size: var(--standart);
  color: var(--black);
  line-height: 13px;
  overflow-wrap: break-word;
}
@media (max-width: 1300px) {
  .analytics_panel .analytics_panel-block-name {
    text-align: center;
  }
}
.analytics_panel .analytics_panel-block-amount {
  font-size: var(--titleBlock);
}
.analytics_panel .analytics_panel-block-percentage {
  font-size: var(--underInput);
}

.objects-on-map-count-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 20px;
  font-weight: 400;
  height: 47px;
  margin-bottom: 16px;
}

.objects-on-map-count-wrapper p {
  margin: 0;
}

.objects-on-map-count-wrapper span {
  font-size: 32px;
}

.objects-on-map-url svg {
  width: 25px;
  height: 16px;
}

.objects-on-map-url a {
  display: flex;
  align-items: center;
  font-size: 20px;
  gap: 16px;
  text-decoration: none;
  color: #000;
}

.objects-on-map-url a:hover {
  color: var(--hover);
  transition: .3s;
}

/*# sourceMappingURL=styles.css.map */
